<template>
  <div class="w-full py-4 flex items-center px-6 bg-primary text-white font-medium text-center justify-center"
       v-if="$route.query.ref && $route.query.ref==='new_login'">
    <span>Bienvenue sur votre nouveau back-office Delicity ! <br> Enregistrez cette page pour vous connecter plus rapidement la prochaine fois. Vous devez entrez à nouveau votre mot de passe.</span>
  </div>
  <auth-layout>
    <div class="mb-5">
      <label class="form-label">Email de votre compte: </label>
      <input type="email" @keydown.enter="login" placeholder="email@domaine.com" v-model="email" class="form-input"/>
    </div>
    <div class="mb-5">
      <label class="form-label">Mot de passe:</label>
      <input type="password" @keydown.enter="login" v-model="password" class="form-input" placeholder="Entrez votre mot de passe"/>
    </div>

    <div v-if="error" class="text-red-600 bg-red-100 rounded px-4 py-2 mb-4 text-sm font-medium">{{error}}</div>

    <div class="flex flex-col items-start gap-3 mt-8">
      <button class="btn bg-blue-500 text-white mb-2" @click="login">Connexion</button>
      <a class="text-blue-500"  target="_blank" :href="forgotLink">Mot de passe oublié ?</a>
      <a class="text-blue-500 flex items-center" href="https://pro.delicity.com">Inscription restaurant<i class="material-icons text-lg ml-2">arrow_forward</i></a>
    </div>
  </auth-layout>
</template>

<script>
import AuthLayout from "@/components/Layouts/AuthLayout.vue";
import {mapActions} from "vuex";
import {getCurrentAccount} from "@/services/AccountService";
export default {
  components: {AuthLayout},
  data(){
    return {
      email: null,
      password: null,
      error: false,
    }
  },
  computed: {
    forgotLink(){
      return 'https://auth.delicity.com/forgot-password?email='+ encodeURIComponent(this.email);
    }
  },
  methods: {
    async tokenLogin(token){
      this.$store.dispatch('loginTokenAction', {token})
          .then(async () => {
            await getCurrentAccount()
                .then((data) => {
                  this.$store.commit('account/SET_ACCOUNT', data);
                  this.$store.dispatch('getMerchantsAction');
                  this.$router.push({name: 'analytics'});
                })
                .catch((e) => {
                  localStorage.clear();
                  this.error = this.$t('errors.not_merchant_account')
                })
          })
    },

    async login(){
      this.$store.dispatch('loginAction', {username: this.email, password: this.password})
          .then(async () => {
            await getCurrentAccount()
                .then((data) => {
                  this.$store.commit('account/SET_ACCOUNT', data);
                  this.$store.dispatch('getMerchantsAction');
                  this.$router.push({name: 'analytics', query: {fromAuth: 'fromAuth'}});
                })
                .catch((e) => {
                  localStorage.clear();
                  this.error = this.$t('errors.not_merchant_account')
                })
          })
          .catch((e) => {
            if(e.response.data && e.response.data.message && this.$te('errors.'+e.response.data.message)){
              this.error = this.$t('errors.'+e.response.data.message)
            }
            else{
              this.error = this.$t('errors.unknown')
            }
          })
    },
  },
  mounted() {
    if(this.$route.query.email){
      this.email = this.$route.query.email;
    }
    if(this.$route.query.token){
      this.tokenLogin(this.$route.query.token);
    }
  }
}
</script>
