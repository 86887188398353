<template>
  <merchant-layout title="Horaires d'ouverture">
    <div class="grid xl:grid-cols-2 gap-4 items-start">
      <Timetable/>
      <Closure/>
    </div>
  </merchant-layout>
</template>

<script>
import {mapGetters} from "vuex";
import Timetable from "@/components/Hours/Timetable.vue";
import Closure from "@/components/Hours/Closure.vue";

export default {
  components: {
    Closure,
    Timetable
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  async mounted() {
        while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }
}
</script>
