<template>
  <router-view :key="key"/>
  <ModalsContainer/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { ModalsContainer } from 'vue-final-modal'
import {computed} from "vue";
import EventBus from "@/EventBus";

export default {
  components: {ModalsContainer},
  methods: {
    ...mapActions(['getMerchantsAction', 'initAuth']),
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
  },
  data(){
    return {
      key: (Math.random() + 1).toString(36).substring(7)
    }
  },
  mounted() {
    if(!this.currentMerchant) this.$store.dispatch('getMerchantsAction');
    EventBus.on('system:refresh-view', () => {
      this.key = (Math.random() + 1).toString(36).substring(7);
    })
    this.initAuth();
  }
}
</script>
