<template>
  <merchant-layout :fixed-action="!isMenuCurrentlySync" :title="$t('nav.products')" :is-loading="isLoading">

    <template #top>
      <MenuCurrentlySync/>
    </template>


    <template v-slot:actions v-if="currentMerchant">
      <loader v-show="isMenuEditionLoading"/>
      <selectionAction/>
      <search-input :placeholder="$t('menu.search')" v-model="search" v-if="!showNoProduct && mode!=='sort' && !selectedMenuItems.length"/>

      <div class="flex shrink-0 border rounded-md overflow-hidden hidden h-10 md:block h-[31.5px]" v-if="currentMerchant.features.menuEditionEnabled && !showNoProduct && !selectedMenuItems.length" >
        <button @click="mode='category'" class="px-4 h-full text-sm" :class="[mode==='category'?'bg-primary text-white font-bold':'bg-white']">{{ $t('menu.view_category') }}</button>
        <button @click="mode='table'" class="px-4 h-full text-sm" :class="[mode==='table'?'bg-primary text-white font-bold':'bg-white']">{{ $t('menu.view_table') }}</button>
        <button @click="mode='sort'" class="px-4 h-full text-sm" :class="[mode==='sort'?'bg-primary text-white font-bold':'bg-white']">{{ $t('menu.view_sort') }}</button>
      </div>

      <menu-button custom-button icon="more_vert" icon-class="text-white" v-if="currentMerchant.features.menuEditionEnabled && !selectedMenuItems.length" >
        <template v-slot:custom-button>
          <button class="btn bg-primary h-9 text-white rounded-md flex items-center pr-2">{{$t('cta.add')}}<i class="material-icons ml-2">arrow_drop_down</i></button>
        </template>
        <template v-slot:menu>
          <menu-button-item icon="lunch_dining" text="Ajouter un nouveau produit" @click="createProduct"/>
          <menu-button-item icon="view_list" text="Ajouter une catégorie" @click="createCategory"/>
        </template>
      </menu-button>

      <menu-button custom-button icon="more_vert" icon-class="text-white" v-if="currentMerchant.features.menuEditionEnabled && !selectedMenuItems.length">
        <template v-slot:custom-button>
          <button class="btn bg-white border h-9 text-gray-600 rounded-md flex items-center px-2"><i class="material-icons">more_horiz</i></button>
        </template>
        <template v-slot:menu>
          <menu-button-item icon-class="text-gray-600 text-lg" :icon="!archivedItemsVisibility ? 'visibility' : 'visibility_off'" :text="!archivedItemsVisibility ? $t('menu.show_archived_items') : $t('menu.no_show_archived_items')" @click="updateArchivedVisibility"/>
        </template>
      </menu-button>

    </template>

    <div class="bg-primary/5 px-5 py-4 rounded-md mb-5" v-if="!currentMerchant.features.menuEditionEnabled">
      <span class="text-gray-700">L'édition du menu est désactivée car une intégration est responsable de l'édition de ce dernier.</span>
    </div>


    <MenuViewCategory v-if="mode==='category' && !showNoProduct && menu" :search="search"/>
    <MenuViewTable v-if="mode==='table' && !showNoProduct && menu" :search="search"/>
    <MenuViewSort v-if="mode==='sort' && !showNoProduct && menu"/>

    <div class="bg-white border rounded-lg" v-if="showNoProduct">
      <table-no-elements title="Créez votre menu Delicity"
                         description="Commencez par ajouter des catégories de produits, ensuite ajoutez vos produits.">
        <template v-slot:button>
          <div class="flex flex-wrap items-center justify-center gap-4 mt-5">
            <button class="btn bg-primary text-white flex items-center gap-2 pr-5" @click="createCategory">Ajouter une catégorie<i class="material-icons text-2xl">add</i></button>
            <button class="btn bg-primary text-white flex items-center gap-2 pr-5" @click="showArticle(8222308)">Vidéo explicative<i class="material-icons text-xl">videocam</i></button>
          </div>
        </template>
      </table-no-elements>
    </div>

  </merchant-layout>
</template>

<script>
import {mapGetters} from "vuex";
import MenuViewCategory from "@/components/Menu/MenuViewCategory.vue";
import MenuViewTable from "@/components/Menu/MenuViewTable.vue";
import selectionAction from "@/components/Menu/Elements/SelectionAction.vue";
import EventBus from "@/EventBus";
import {useModal} from "vue-final-modal";
import MenuItemEditor from "@/views/Menu/MenuItemEditor.vue";
import MenuButton from "@/components/Ui/Elements/MenuButton.vue";
import MenuButtonItem from "@/components/Ui/Elements/MenuButtonItem.vue";
import MenuCategoryEditor from "@/views/Menu/MenuCategoryEditor.vue";
import AiMenuModal from "@/components/Menu/Modals/AiMenuModal.vue";
import SearchInput from "@/components/Ui/Elements/SearchInput.vue";
import {showArticle} from "@/services/SystemService";
import TableNoElements from "@/components/Ui/Elements/TableNoElements.vue";
import MenuViewSort from "@/components/Menu/MenuViewSort.vue";
import MenuCurrentlySync from "@/components/Menu/Elements/MenuCurrentlySync.vue";

export default {
    components: {
      MenuCurrentlySync,
      MenuViewSort,
      SearchInput, TableNoElements, MenuViewCategory, MenuViewTable, selectionAction, MenuButtonItem, MenuButton},
    setup(){
        const productModal = useModal({component: MenuItemEditor});
        const categoryModal = useModal({component: MenuCategoryEditor});
        const menuAi = useModal({component: AiMenuModal});
        return {productModal, categoryModal, menuAi}
    },
    data(){
        return {
            mode: 'table',
            search: '',
            isLoading: true
        }
    },
    methods: {
      showArticle,
        openMenuAI(){
            this.menuAi.open();
        },
        async createProduct(){
            await this.productModal.patchOptions({attrs: {}});
            await this.productModal.open();
        },
        async createCategory(){
            await this.categoryModal.patchOptions({attrs: {}});
            await this.categoryModal.open();
        },
        async load(){
            await this.$store.dispatch('menu/getMerchantMenuAction', {merchantId: this.currentMerchant.id})
        },
        updateArchivedVisibility(){
            this.$store.commit('menu/SET_ARCHIVED_ITEM_VISIBILITY', !this.archivedItemsVisibility)
            EventBus.emit('menu:category:update-category-check');
        }
    },
    computed: {
      ...mapGetters(['currentMerchant']),
      ...mapGetters({
        menu: 'menu/menu',
        archivedItemsVisibility: 'menu/archivedItemsVisibility',
        selectedMenuItems: 'menu/selectedMenuItems',
        isMenuEditionLoading: 'menu/isMenuEditionLoading',
        isMenuCurrentlySync: 'menu/isMenuCurrentlySync'
      }),
      showNoProduct(){
          return this.menu && this.menu.categories.filter(c => this.archivedItemsVisibility ? true : c.archived===false).length === 0
      }
    },
    async mounted() {
      if (window.innerWidth < 800) this.mode = 'category'
          while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
      if(!this.menu || this.menu && this.menu.merchantId !== this.currentMerchant.id) await this.load();
      this.isLoading = false;

      this.search = this.$route.query.search || '';
    }
}
</script>
