<template>
  <merchant-layout :title="$t('nav.booking')" :is-loading="!bookingSettings">
    <template v-slot:actions  v-if="bookingSettings && bookingSettings.bookingEnabled">
      <button class="btn border-primary border text-primary items-center flex" v-if="!(bookingToConfirm.length === 0 && bookingGroup.length === 0)" @click="showHelp('8027212')"><i class="material-icons text-base mr-2">trending_up</i>Comment booster mes réservations ?</button>
      <button class="btn bg-primary text-white" :disabled="currentMerchant.status === 'building'" @click="addBooking">Nouvelle réservation</button>
    </template>

    <template v-if="bookingSettings && bookingSettings.bookingEnabled">

      <BookingList title="A confirmer" is-important :bookings="bookingToConfirm" v-if="bookingToConfirm.length>0"></BookingList>
      <template v-for="group in bookingGroup">
        <BookingList :title="group[0].booking_for_day" :bookings="group"></BookingList>
      </template>

      <page-block no-title-padding v-if="bookingToConfirm.length === 0 && bookingGroup.length === 0">
        <table-no-elements title="Pas de réservation" description="Aucun client n'a réservé de table, Consultez nos articles d'aide pour faire décoller votre activité">
          <template v-slot:button>
            <button class="btn text-white bg-primary items-center flex mt-4" @click="showHelp('8027212')"><i class="material-icons text-base mr-2">trending_up</i>Comment booster mes réservations ?</button>
          </template>
        </table-no-elements>
      </page-block>

    </template>
    <template v-else>
      <div class="w-full flex flex-col md:items-start items-center px-10 md:px-0 text-center md:text-left">
        <span class="text-xl">La réservation de table n'est pas activée dans votre restaurant.</span>
        <button class="btn bg-primary text-white mt-4 py-2 pr-5 flex items-center h-auto leading-tight" @click="enableBooking">Permettre à mes clients de réserver en ligne<i class="material-icons text-xl ml-2">arrow_forward</i></button>
      </div>
    </template>



  </merchant-layout>
</template>

<script>
import {mapGetters} from "vuex";
import BookingList from "@/components/Booking/BookingList.vue";
import BookingEditor from "@/components/Booking/Modal/BookingEditor.vue";
import {useModal} from "vue-final-modal";
import {showArticle} from "@/services/SystemService";
import {DateTime} from "luxon";
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";
import TableNoElements from "@/components/Ui/Elements/TableNoElements.vue";
export default {
  setup(){
    const createBooking = useModal({component: BookingEditor});
    return {createBooking}
  },
  components: {TableNoElements, PageBlock, BookingList},
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant',
      bookings: 'booking/bookings',
      bookingSettings: 'booking/bookingSettings',
      bookingToConfirm: 'booking/bookingToConfirm',
      bookingCurrentMerchantId: 'booking/bookingCurrentMerchantId'
    }),

    bookingGroup(){
      if(!this.bookings || this.bookings.items && this.bookings.items.length === 0){
        return [];
      }
      let bookingGroup = this.bookings.items.reduce(function (r, a) {
        const currentDay = DateTime.fromISO(a.booking_for).setLocale('fr').toFormat('EEEE dd LLLL');
        a.booking_for_day = currentDay;
        a.booking_for_day_time = DateTime.fromISO(a.booking_for).toSeconds();
        r[currentDay] = r[currentDay] || [];
        r[currentDay].push(a);
        return r;
      }, Object.create(null));
      return Object.values(bookingGroup).sort(function (a, b) {
        return a[0].booking_for_day_time - b[0].booking_for_day_time;
      });
    }
  },

  data(){
    return {
      page: 1,
      isPageLoading: false
    }
  },
  methods: {
    enableBooking(){
      this.$store.dispatch('booking/enableBooking', {merchantId: this.currentMerchant.id});
    },
    addBooking(){
      this.createBooking.patchOptions({attrs: {bookingData: null}});
      this.createBooking.open();
    },
    showHelp(id){
      showArticle(id);
    },
    async load(page=1){
      this.page = page;
      this.isPageLoading = true;
      this.$store.dispatch('booking/getBookingList', {merchantId: this.currentMerchant.id, page: this.page})
      this.isPageLoading = false;
    }
  },
  async mounted() {
        while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    if(!this.bookingSettings || this.bookingCurrentMerchantId !== this.currentMerchant.id) await this.$store.dispatch('booking/getSettings', {merchantId: this.currentMerchant.id});
    this.$store.dispatch('booking/getToConfirmBookings', {merchantId: this.currentMerchant.id});
    this.load();
  }
}
</script>
