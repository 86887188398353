<template>
    <merchant-layout :title="$t('nav.marketing')">

      <div class="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-5 gap-4">

        <div class="bg-primary shadow-lg rounded-md overflow-hidden flex flex-col">
          <div class="h-20 w-full flex items-center px-6">
            <i class="material-icons text-[2.6rem] text-white">auto_stories</i>
          </div>
          <div class="pb-1 pt-4 px-6 pr-14">
            <span class="uppercase text-white font-bold text-lg leading-tight">Nos conseils marketing</span>
          </div>
          <div class="flex-1 px-6 text-white leading-tight">
            <span class="text-sm">Développer votre image et recevez plus de commandes en découvrant les bonnes pratiques à appliquer</span>
          </div>
          <div class="flex justify-start px-6 py-5">
            <a href="https://www.notion.so/delicity/Bonnes-pratique-Marketing-4c7e0a1082ce4526bb46d1aec27d9a56?pvs=4" target="_blank" class="btn bg-white text-primary block flex items-center h-9 text-sm">Lire</a>
          </div>
        </div>

        <MarketingBlock :href="'https://delicity.fillout.com/campagne-promo?merchant_id='+currentMerchant.id+'&merchant_name='+currentMerchant.name"
                        header-color="#FFAEA3"
                        :description="$t('marketing.discount_description')"
                        icon="discount"
                        title="Créer une promotion"/>

        <MarketingBlock :href="'https://forms.delicity.com/marketing/geste-commercial?merchant_id='+currentMerchant.id+'&merchant_name='+currentMerchant.name"
                        header-color="#F2C691"
                        :description="$t('marketing.commercial_gesture_description')"
                        icon="local_activity"
                        title="Créer un geste commercial"/>

        <MarketingBlock :href="'https://delicity.fillout.com/t/3ymhey2hw6us?Prestation=Flyers&Merchant_id=' + currentMerchant.id"
                        header-color="#ABD79C"
                        :description="$t('marketing.print_description')"
                        icon="pageview"
                        title="Impression de Flyers"/>

        <MarketingBlock :href="'https://delicity.fillout.com/t/3ymhey2hw6us?Prestation=Shooting&Merchant_id=' + currentMerchant.id"
                        header-color="#95BFE5"
                        :description="$t('marketing.media_shooting_description')"
                        icon="collections"
                        title="Shooting Photo & Vidéo"/>

        <MarketingBlock :href="'https://forms.delicity.com/marketing/budget-pub?merchant_id='+currentMerchant.id+'&merchant_name='+currentMerchant.name"
                        header-color="#FD8DFF"
                        :description="$t('marketing.ad_budget_description')"
                        icon="campaign"
                        title="Gérer mon Budget publicitaire"/>

        <MarketingBlock :href="'https://forms.delicity.com/marketing/envoi-sms?merchant_id='+currentMerchant.id+'&merchant_name='+currentMerchant.name"
                        header-color="#5EE092"
                        :description="$t('marketing.sms_campaign_description')"
                        icon="phone_iphone"
                        title="Envoi campagne de sms"/>

        <MarketingBlock :href="'https://forms.delicity.com/marketing/contacter-influencer?merchant_id='+currentMerchant.id+'&merchant_name='+currentMerchant.name"
                        header-color="#D0BFA4"
                        :description="$t('marketing.contact_influencer_description')"
                        icon="verified"
                        title="Contacter un Influenceur"/>

        <MarketingBlock :href="'https://forms.delicity.com/marketing/roulette-concours?merchant_id='+currentMerchant.id+'&merchant_name='+currentMerchant.name"
                        header-color="#93D7CE"
                        :description="$t('marketing.lucky_wheel_description')"
                        icon="casino"
                        title="Roulette concours"/>

        <!--
        <MarketingBlock :href="'https://forms.delicity.com/marketing/programme-fidelite?merchant_id='+currentMerchant.id+'&merchant_name='+currentMerchant.name"
                        header-color="#C9D793"
                        :description="$t('marketing.fidelity_description')"
                        icon="badge"
                        title="Programme FIdélité"/>
-->
      </div>

    </merchant-layout>
</template>


<script>
import {mapGetters} from "vuex";
import MarketingBlock from "@/components/Marketing/MarketingBlock.vue";
export default {
  components: {MarketingBlock},
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
  },
  async mounted() {
        while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }
}
</script>
