<template>
  <merchant-layout :title="$t('clients.title', {count:pagination ? pagination.totalItems : 0})">

    <template v-if="currentMerchant && !currentMerchant.features.clientDetailsEnabled" v-slot:afterTitle>
      <div class="locked-feature-badge">
        <i class="material-icons text-sm mr-1">lock</i><span class="text-sm font-medium">Limité</span>
      </div>
    </template>

    <template v-slot:actions  v-if="clients && !error">
      <div class="relative flex">
        <div class="flex items-center absolute w-10 justify-center items-center h-full">
          <i class="material-icons">search</i>
        </div>
        <input type="text" class="form-input pl-10" placeholder="Rechercher un client..." v-model="search" @input="updateSearch">
      </div>
      <button class="btn bg-primary text-white" :disabled="!currentMerchant.features.clientDetailsEnabled" @click="exportClients">Export Excel</button>
    </template>

    <page-block no-title-padding >
      <template v-slot:no-padding>
        <table class="page-table">
          <thead>
          <tr class="text-left">
           <!-- <th class="w-8"></th>-->
            <th><span class="cursor-pointer flex items-center" @click="toggleSort('name')">Nom du client <i class="material-icons" v-if="sortField==='name'">{{sortOrder==='desc'?'arrow_drop_up':'arrow_drop_down'}}</i></span></th>
            <th></th>
            <th><span class="flex items-center">Téléphone</span></th>
            <th><span class="cursor-pointer flex items-center" @click="toggleSort('email')">Email <i class="material-icons"  v-if="sortField==='email'">{{sortOrder==='desc'?'arrow_drop_up':'arrow_drop_down'}}</i></span></th>
            <th><span class="cursor-pointer flex items-center" @click="toggleSort('order_count')">Commandes <i class="material-icons"  v-if="sortField==='order_count'">{{sortOrder==="desc"?'arrow_drop_up':'arrow_drop_down'}}</i></span></th>
            <th><span class="cursor-pointer flex items-center">Réservations <i class="material-icons"  v-if="sortField==='bookings'">{{sortOrder==="desc"?'arrow_drop_up':'arrow_drop_down'}}</i></span></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="client in clients" :key="client.userId" v-if="!error">
            <!--
            <td class="border-t" style="padding-right: 0 !important;">
              <div class="w-8 h-full items-center flex justify-center">
              <input type="checkbox" class="form-checkbox m-0">
              </div>
            </td>
             -->
            <td class="px-4 py-2 border-t">
              <div class="flex items-center">

                <img :src="client.avatar" class="w-11 h-11 rounded-full">

                <div class="flex flex-col h-full items-start ml-3">
                  <span class="font-bold leading-tight mb-1">{{ client.name }}</span>
                <!--  <span class="flex items-center bg-yellow-400 text-white text-xs px-2 h-4 rounded-full gap-1 font-bold"><i class="material-icons text-sm">star</i>Client VIP</span> -->
                </div>
              </div>
            </td>
            <td class="px-4 py-2 border-t"></td>

            <td class="px-4 py-2 border-t">
              <span class="text-sm" :class="{'blur-sm select-none': !currentMerchant.features.clientDetailsEnabled}">{{client.phone}}</span>
            </td>

            <td class="px-4 py-2 border-t">
              <span class="text-sm" :class="{'blur-sm select-none': !currentMerchant.features.clientDetailsEnabled}">{{client.email}}</span>
            </td>
            <td class="px-4 py-2 border-t">
              <div class="flex items-center gap-2" :class="{'opacity-50':client.order_count===0}">
                <i class="material-icons text-lg leading-none">shopping_bag</i>
                <span class="font-bold text-lg leading-none">{{client.order_count}}</span>
              </div>
            </td>
            <td class="px-4 py-2 border-t">
              <div class="flex items-center gap-2" :class="{'opacity-50':client.booking_count===0}">
                <i class="material-icons text-lg leading-none">event_available</i>
                <span class="font-bold text-lg leading-none">{{client.booking_count}}</span>
              </div>
            </td>

          </tr>
          </tbody>
        </table>

        <table-no-elements v-if="error === 'no_clients_found'" title="Pas de client pour l'instant" description="Commencez à recevoir des commandes Delicity pour voir la liste de vos clients ici même."/>

      </template>
    </page-block>

    <Pagination class="my-10" :pagination="pagination" @changePage="addPage" :is-loading="isLoading"/>
  </merchant-layout>
</template>



<script>
import {mapGetters} from "vuex";
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";
import {exportClientList, getClientList} from "@/services/ClientService";
import Pagination from "@/components/Ui/Elements/Pagination.vue";
import {useModal} from "vue-final-modal";
import ClientPage from "@/views/Clients/ClientPage.vue";
import TableNoElements from "@/components/Ui/Elements/TableNoElements.vue";
export default {
  components: {TableNoElements, Pagination, PageBlock},
  setup(){
    const clientModal = useModal({component: ClientPage})
    return {clientModal}
  },
  data(){
    return {
      error: false,
      search: '',
      searchTimeout: false,
      selectedClient: [],
      clients: null,
      pagination: null,
      isLoading: false,
      page: 1,
      sortField: 'order_count',
      sortOrder: 'desc'
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
  },
  methods: {
    toggleSort(type){
      if(this.sortField === type){
        this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
      }
      else{
        this.sortOrder = 'desc';
      }
      this.sortField = type;
      // vue router add in query
      this.$router.push({query: {sortField: this.sortField, sortOrder: this.sortOrder}});
      this.loadPage();
    },
    async exportClients(){
      await exportClientList(this.currentMerchant.id);
    },
    updateSearch(){
      if(this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.page = 1;
        this.loadPage();
      }, 500);
    },
    openClientPage(id){
      this.clientModal.patchOptions({attrs: {clientId: id}});
      this.clientModal.open();
    },
    addPage(page){
      this.page = page;
      this.loadPage();
    },
    async loadPage(){
      await getClientList(this.currentMerchant.id, this.page, 40, this.search, this.sortField, this.sortOrder)
          .then(({ clients, pagination }) => {
            this.clients = clients;
            this.pagination = pagination;
          })
          .catch((e) => {
            console.log(e);
            if(e.response && e.response.data.error === 'no_clients_found'){
              this.error = 'no_clients_found';
            }
          });
    }
  },
  async mounted() {
        while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    if(this.$route.query.sortField !== undefined) this.sortField = this.$route.query.sortField;
    if(this.$route.query.sortOrder !== undefined) this.sortOrder = this.$route.query.sortOrder;
    await this.loadPage();
  }
}
</script>
