<template>
  <page-block title="Fermetures exceptionnelles">

    <div class="flex flex-col items-start" v-if="currentMerchant.schedule.closes.length===0">
      <span class="text-gray-600">Pas de fermeture exceptionnelle pour le moment</span>
    </div>

    <div class="border rounded-xl" v-else>
      <div v-for="close in currentMerchant.schedule.closes.filter(e => e.mode === 'ponctual')" :key="close.id" class="border-b last:border-0 flex items-center justify-between h-14">

        <div class="flex items-center pl-4 gap-2">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" class="mr-4 hidden md:block" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.8346 0.42C2.2146 0.14 2.6246 0 3.0546 0H16.9346C17.3746 0 17.7646 0.14 18.1246 0.42C18.4946 0.7 18.7246 1.06 18.8546 1.5L19.8846 5.91C20.1346 7 19.9346 7.95 19.2746 8.77C19.2246 8.82 19.1746 8.87 19.1246 8.92L18.9946 9.06C18.4246 9.65 17.7546 10 16.8046 10C15.8546 10 15.1046 9.62 14.5346 9.05C13.9046 9.67 13.1646 10 12.2946 10C11.4446 10 10.6746 9.7 9.9946 9.07C9.4346 9.63 8.6346 10 7.7946 10C6.8546 10 6.0946 9.67 5.4646 9.05C4.9046 9.59 4.1546 10 3.2146 10C2.2746 10 1.6246 9.64 1.0346 9.07C0.924599 8.97 0.804599 8.85 0.684599 8.73C0.0545995 7.92 -0.145401 7 0.104599 5.91L1.1346 1.5C1.2346 1.06 1.4546 0.7 1.8346 0.42ZM17.9646 6.38L16.8846 1.97L14.9646 2L15.5746 6.84C15.6046 7.16 15.7246 7.43 15.9446 7.66C16.1646 7.9 16.4346 8 16.7446 8C17.1246 8 17.4346 7.86 17.6846 7.55C17.9646 7.2 18.0546 6.81 17.9646 6.38ZM5.0246 2H3.0546L2.0246 6.38C1.9346 6.81 2.0246 7.2 2.3046 7.55C2.5246 7.86 2.8346 8 3.2446 8C3.9046 8 4.2946 7.63 4.4146 6.84L5.0246 2ZM8.9946 2H7.0446L6.4946 6.5V6.66C6.4946 7 6.6046 7.3 6.8346 7.59C7.0646 7.88 7.3546 8 7.6946 8C8.0746 8 8.3846 7.88 8.6346 7.62C8.8846 7.35 8.9946 7.05 8.9946 6.7V2ZM12.9446 2H10.9946V6.7C10.9946 7.05 11.1046 7.35 11.3546 7.62C11.6046 7.88 11.8846 8 12.1946 8C12.6046 8 12.9446 7.87 13.2046 7.57C13.4946 7.27 13.5746 6.92 13.4946 6.5L12.9446 2ZM18.1146 11.46L19.5346 12.88L17.4046 15L19.5346 17.12L18.1146 18.54L15.9946 16.41L13.8746 18.54L12.4646 17.12L14.5846 15L12.4646 12.88L13.8746 11.47L15.9946 13.59L18.1146 11.46ZM9.9946 11.07C10.2746 11.34 10.6046 11.56 10.9646 11.72C10.3546 12.66 9.9946 13.79 9.9946 15C9.9946 16.09 10.2846 17.12 10.7946 18H2.9946C1.8846 18 0.994599 17.11 0.994599 16V11.03C1.6546 11.65 2.3246 12 3.2246 12C4.0946 12 4.8846 11.64 5.4646 11.05C6.0646 11.65 6.8646 12 7.7746 12C8.6446 12 9.4246 11.64 9.9946 11.07Z" fill="black"/>
          </svg>

          <template v-if="isToday(close.punctualStart, close.punctualEnd)">
            <span class="text-sm md:text-base text-center md:text-left">{{ formatDate(close.punctualStart, 'ccc dd LLL') }} (Toute la journée)</span>
          </template>
          <template v-else-if="isTomorrow(close.punctualStart, close.punctualEnd)">
            <span class="text-sm md:text-base text-center md:text-left">{{ formatDate(close.punctualStart, 'ccc dd LLL') }} (Toute la journée)</span>
          </template>
          <template v-else>
            <span class="capitalize text-sm md:text-base text-center md:text-left">{{ formatDate(close.punctualStart, 'ccc dd LLL HH:mm') }}</span>
            <i class="material-icons text-lg">arrow_forward</i>
            <span class="capitalize text-sm md:text-base  text-center md:text-left">{{ formatDate(close.punctualEnd, 'ccc dd LLL HH:mm') }}</span>
          </template>

        </div>

        <div class="flex items-center">
          <button class="btn bg-gray-200 hover:ring-0 px-3 md:px-6" @click="editClosure(close)">Modifier</button>
          <button class="h-10 pl-5 pr-5 flex items-center" @click="deleteClosure(close)"><i class="material-icons text-xl">close</i></button>
        </div>
      </div>
    </div>

    <button class="btn bg-primary text-white mt-5" @click="addClosure">Ajouter</button>
  </page-block>
</template>

<script>
import {mapGetters} from "vuex";
import {useModal} from "vue-final-modal";
import ClosureEditorModal from "@/components/Hours/Modals/ClosureEditorModal.vue";
import {formatDate} from "../../services/FormatService";
import {deleteMerchantClosure} from "@/services/MerchantService";
import confirmDeleteClosure from "@/components/Hours/Modals/ConfirmDeleteClosure.vue";
import {DateTime} from "luxon";

export default {
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  setup(){
    const createClosureModal = useModal({component: ClosureEditorModal})
    const deleteClosureModal = useModal({component: confirmDeleteClosure})
    return {createClosureModal, deleteClosureModal};
  },
  methods: {
    isToday(start, end){
      const todayStart = DateTime.now().startOf('day').toSeconds();
      const todayEnd = DateTime.now().plus({day:1}).startOf('day').toSeconds();
      return DateTime.fromISO(start).toSeconds() === todayStart && DateTime.fromISO(end).toSeconds() === todayEnd;
    },
    isTomorrow(start, end){
      const todayStart = DateTime.now().plus({day:1}).startOf('day').toSeconds();
      const todayEnd = DateTime.now().plus({day:2}).startOf('day').toSeconds();
      return DateTime.fromISO(start).toSeconds() === todayStart && DateTime.fromISO(end).toSeconds() === todayEnd;
    },
    formatDate,
    addClosure(){
      this.createClosureModal.patchOptions({attrs: { closure: null}});
      this.createClosureModal.open();
    },
    editClosure(closure){
      this.createClosureModal.patchOptions({attrs: { closure }});
      this.createClosureModal.open();
    },
    async deleteClosure(closure){
      this.deleteClosureModal.patchOptions({attrs: { closure }});
      this.deleteClosureModal.open();
    }
  },
  async mounted() {
    while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }
}
</script>
