<template>
  <merchant-layout title="Statistiques">


    <template v-slot:actions>
      <vue-tailwind-datepicker id="tour_statsDate" i18n="fr" :options="options" :formatter="formatter" v-model="datePickerValue" />
    </template>




    <template v-if="fameData">
      <ChartBlock id="tour_statsChart" class="mb-4" key="main" title="Visites" :labels="fameData.visitsChart.label" :values="fameData.visitsChart.values" :type="fameData.visitsChart.type"/>
      <div class="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-8">
        <StatBlock id="tour_statsNumberVisits" :show-evolution="true" :value="fameData.totalVisits.value" :evolution="fameData.totalVisits.evolution" title="Visites de la page"/>
        <StatBlock id="tour_statsNumberFavorites" :show-evolution="true" :value="fameData.totalFavorites.value" :evolution="fameData.totalFavorites.evolution" title="Personnes ayant mis le restaurant en favori"/>
        <StatBlock id="tour_statsNumberTries" :show-evolution="true" :value="fameData.totalWishingTest.value" :evolution="fameData.totalWishingTest.evolution" title="Personnes souhaitant tester votre restaurant"/>
        <StatBlock id="tour_statsNumberOrders" :show-evolution="true" :value="formatPercentage(fameData.conversionRateOrder.value)" :evolution="fameData.conversionRateOrder.evolution" title="Conversion Commandes"/>
        <StatBlock id="tour_statsNumberBookings" :show-evolution="true" :value="formatPercentage(fameData.conversionRateBooking.value)" :evolution="fameData.conversionRateBooking.evolution" title="Conversion Réservations"/>
      </div>
    </template>

    <template v-else>
      <ChartBlock class="mb-4" :is-loading="true" title="Visites"/>
      <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-8">
        <StatBlock :is-loading="true" title="Visites de la page"/>
        <StatBlock :is-loading="true" title="Personnes ayant mis le restaurant en favori"/>
        <StatBlock :is-loading="true" title="Personnes souhaitant tester votre restaurant"/>
        <StatBlock :is-loading="true" title="Conversion Commandes"/>
        <StatBlock :is-loading="true" title="Conversion Réservations"/>
      </div>
    </template>



    <hr class="mb-8">




    <div class="flex justify-between gap-4 mt-4 mb-6">
      <span class="text-2xl block uppercase font-bold">{{ $t('analytics.finance') }}</span>
    </div>
    <template v-if="salesData">
      <ChartBlock id="tour_financesChart" class="my-4" title="Ventes" :labels="salesData.salesChart.label" :values="salesData.salesChart.values" :type="salesData.salesChart.type"/>
      <div class="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-8">
        <StatBlock id="tour_financesNumberOrders" :show-evolution="true" :value="salesData.totalSales.value" :evolution="salesData.totalSales.evolution" title="Nombre Commandes" subtitle="sur la période"/>
        <StatBlock id="tour_financesTotalRevenue" :show-evolution="true" :value="formatBigPrice(salesData.totalTurnover.value)" :evolution="salesData.totalTurnover.evolution" title="CA Total" subtitle="total produits + frais livraison"/>
        <StatBlock id="tour_financesNetRevenue" :show-evolution="true" :value="formatBigPrice(salesData.totalNetProfit.value)" :evolution="salesData.totalNetProfit.evolution" title="Net Perçu" subtitle="CA Total - suppl produits"/>
        <StatBlock id="tour_financesServiceCost" :show-evolution="true" :value="formatPercentage(salesData.totalPlatformCost.value)" :evolution="salesData.totalPlatformCost.evolution" :evolution-inverted="true" title="Coût Delicity" subtitle="CA Produits sur place / Net Perçu"/>
        <StatBlock id="tour_financesSavings" :show-evolution="true" :value="formatBigPrice(salesData.estimatedSavings.value)" :evolution="salesData.estimatedSavings.evolution" title="Economies estimées" subtitle="vs plateforme (base 30%ht)"/>
      </div>

    </template>
    <template v-else>
      <ChartBlock class="mb-4" :is-loading="true" title="Ventes"/>
      <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-8">
        <StatBlock :is-loading="true" title="Nombre Commandes" subtitle="sur la période"/>
        <StatBlock :is-loading="true" title="CA Total" subtitle="total produits + frais livraison"/>
        <StatBlock :is-loading="true" title="Net Perçu" subtitle="CA Total - suppl produits"/>
        <StatBlock :is-loading="true" title="Coût Delicity" subtitle="CA Produits sur place / Net Perçu"/>
        <StatBlock :is-loading="true" title="Economies estimées" subtitle="vs plateforme (base 30%ht)"/>
      </div>
    </template>

    <hr class="mb-8">

    <template v-if="habitsData">
      <div class="flex justify-between gap-4 mt-4 mb-6">
        <span class="text-2xl block uppercase font-bold">{{ $t('analytics.habits') }}</span>
      </div>
      <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">
        <type-repartition id="tour_habitsRecuperation" :repartition="habitsData.typeRepartition"/>
        <DoughnutBlock id="tour_habitsOriginClients" :labels="habitsData.clientOrigin.label" :values="habitsData.clientOrigin.values" title="Origine des clients"/>
        <DoughnutBlock id="tour_habitsNewClients" :labels="habitsData.newClients.label" :values="habitsData.newClients.values" title="Nouveaux clients"/>
      </div>
      <div class="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 mb-4">
        <StatBlock id="tour_habitsPreparationTime" :value="formatDuration(habitsData.avgPreparationDuration)" title="Temps préparation"/>
        <StatBlock id="tour_habitsPreparationAndDeliveryTime" :value="formatDuration(habitsData.avgDeliveryOrderDuration)" title="Prépa + livraison"/>
        <StatBlock id="tour_habitsMeanDistanceDelivery" :value="formatDistance(habitsData.avgDeliveryDistance)" title="Distance de livraison"/>
        <StatBlock id="tour_habitsMeanCart" :value="formatPrice(habitsData.avgBasket)" title="Panier Moyen"/>
        <StatBlock id="tour_habitsOrdrePerClient" :value="habitsData.avgOrderPerClient" title="Commandes par client"/>
      </div>
      <div class="grid xl:grid-cols-2 gap-4 mb-20">
        <BestClients id="tour_habitsBestClients" :data="habitsData"/>
        <BestProducts id="tour_habitsBestProducts" :data="habitsData"/>
      </div>
    </template>


  </merchant-layout>
</template>

<script>
import {mapGetters} from "vuex";
import StatBlock from "@/components/Ui/Elements/StatBlock.vue";
import ChartBlock from "@/components/Ui/Elements/ChartBlock.vue";
import DoughnutBlock from "@/components/Ui/Elements/DonutBlock.vue";
import MenuButtonItem from "@/components/Ui/Elements/MenuButtonItem.vue";
import MenuButton from "@/components/Ui/Elements/MenuButton.vue";
import {getAnalytics} from "@/services/AnalyticsService";
import {formatDuration, formatBigPrice, formatPercentage, formatDistance, formatPrice} from "@/services/FormatService";
import {DateTime, Interval} from "luxon";
import TypeRepartition from "@/components/Analytics/TypeRepartition.vue";
import BestClients from "@/components/Analytics/BestClients.vue";
import BestProducts from "@/components/Analytics/BestProducts.vue";
import EventBus from "@/EventBus";
import {siteTour, resetTour} from "@/services/TourService";
import { nextTick } from "vue";


export default {
  components: {
    BestProducts,
    BestClients, TypeRepartition, DoughnutBlock, ChartBlock, StatBlock, MenuButtonItem, MenuButton},
  setup(){
    const formatter = {
      date: 'DD/MM/YYYY',
      month: 'MMM'
    }
    const options = {
      shortcuts: {
        today: 'Aujourd\'hui',
        yesterday: 'Hier',
        past: period => period + ' derniers jours',
        currentMonth: 'Mois actuel',
        pastMonth: 'Mois passé'
      },
      footer: {
        apply: 'Appliquer',
        cancel: 'Annuler'
      }
    };

    return {formatter, options}
  },
  data(){
    return {
      datePickerValue: {
        startDate: '',
        endDate: ''
      },
      fameData: null,
      salesData: null,
      habitsData: null,
      isLoading: false
    }
  },
  methods: {
    formatPrice,
    formatDistance,
    formatDuration,
    formatBigPrice,
    formatPercentage,

    async load(){
      this.fameData = null;
      this.salesData = null;
      this.habitsData = null;

      const promises = [];
      promises.push(getAnalytics(this.currentMerchant.id, this.period, 'fame', this.unit).then(data => this.fameData = data));
      promises.push(getAnalytics(this.currentMerchant.id, this.period, 'sales', this.unit).then(data => this.salesData = data));
      promises.push(getAnalytics(this.currentMerchant.id, this.period, 'habits', this.unit).then(data => this.habitsData = data));

      this.isLoading = true;
      await Promise.all(promises)
          .finally(() => {
            this.isLoading = false;
          })
    }

  },
  watch: {
    period(){
      this.load();
    }
  },
  computed: {
    ...mapGetters(['merchants', 'currentMerchant']),
    interval(){
      let start = DateTime.now().minus({days:30}).startOf('day');
      let end = DateTime.now().endOf('day');
      if(this.datePickerValue.startDate && this.datePickerValue.endDate){
        start = DateTime.fromFormat(this.datePickerValue.startDate, 'dd/MM/yyyy').startOf('day');
        end = DateTime.fromFormat(this.datePickerValue.endDate, 'dd/MM/yyyy').endOf('day');
      }
      return Interval.fromDateTimes(start, end);
    },
    period(){
      return this.interval.start.toISO()+'|'+this.interval.end.toISO();
    },
    unit(){
      // ['hours', 'days', 'weeks', 'months', 'years']
      const days = Math.round(this.interval.toDuration('days').values.days);
      const months = Math.round(this.interval.toDuration('months').values.months);

      if(days <= 3) return 'hours';
      else if(days <= 35) return 'days';
      else if(months <= 3) return 'weeks';
      else if(months <= 12) return 'months';
      else return 'years';
    },
    stepsWithConditions() {
      return [
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            intro: 'Bienvenue sur votre back-office marchand ! 👋'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            intro: 'Faisons un tour d\'horizon des fonctionnalités auxquelles vous avez accès'
          },
        },
        // {
        //   toAdd: true,
        //   option: {
        //     title: 'Bienvenue',
        //     element: '#tour_merchantLink',
        //     intro: 'Cliquez ici pour accéder aux informations de votre établissement',
        //     position: 'right'
        //   },
        // },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_statsDate',
            intro: 'Vous pouvez choisir la période qui vous intéresse grâce à ce menu déroulant',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_statsChart',
            intro: 'Retrouvez ici le nombre de visites de votre page ou de votre site',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_statsNumberVisits',
            intro: 'Retrouvez ici le nombre total de visiteurs sur la période',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_statsNumberFavorites',
            intro: 'Retrouvez ici le nombre de personnes qui ont ajouté votre restaurant à leurs adresses favorites',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_statsNumberTries',
            intro: 'Retrouvez ici le nombre de personnes qui souhaitent tester votre restaurant',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_statsNumberOrders',
            intro: 'Retrouvez ici le ratio nombre de paniers / nombre de commandes validées',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_statsNumberBookings',
            intro: 'Retrouvez ici le ratio nombre de visites / nombre de réservations validées',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_financesChart',
            intro: 'Retrouvez ici le nombre de commandes par jour sur la période',
            position: 'top'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_financesNumberOrders',
            intro: 'Retrouvez ici le nombre de commandes sur la période',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_financesTotalRevenue',
            intro: 'Retrouvez ici le total du chiffre d\'affaires sur la période (montant qui inclut la livraison)',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_financesNetRevenue',
            intro: 'Retrouvez ici le net percu sur la période',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_financesServiceCost',
            intro: 'Retrouvez ici le coût total du service Delicity',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_financesSavings',
            intro: 'Retrouvez ici l\'économie estimée par rapport à une marketplace classique avec 30% de commission',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsRecuperation',
            intro: 'Retrouvez ici la répartition des commandes par livraison, Click&Collect et sur place',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsOriginClients',
            intro: 'Retrouvez ici la répartition des clients par provenance (votre site Internet / l\'app Delicity)',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsNewClients',
            intro: 'Retrouvez ici la répartition nouveaux clients / clients existants',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsPreparationTime',
            intro: 'Retrouvez ici le temps moyen de préparation sur la période',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsPreparationAndDeliveryTime',
            intro: 'Retrouvez ici le temps de préparation + livraison moyen sur la période',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsMeanDistanceDelivery',
            intro: 'Retrouvez ici la distance moyenne de livraison sur la période',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsMeanCart',
            intro: 'Retrouvez ici le panier moyen des commandes sur la période',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsOrdrePerClient',
            intro: 'Retrouvez ici le nombre de commandes moyen par client sur la période',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsBestClients',
            intro: 'Retrouvez ici le Top 10 des meilleurs clients sur la période (en CA et en nombre de commande)',
            position: 'right'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_habitsBestProducts',
            intro: 'Retrouvez ici le Top 10 des produits les plus vendus sur la période (en CA et en nombre de commande)',
            position: 'left'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '.intercom-launcher',
            intro: 'A tout moment, en cas de problème, vous pouvez nous contacter en utilisant le chat ici',
            position: 'top'
          },
        },
        {
          toAdd: true,
          option: {
            title: 'Bienvenue',
            element: '#tour_needHelp',
            intro: 'Voilà, c\'est fini ! Vous pouvez relancer ce guide à tout moment en cliquant ici',
            position: 'bottom'
          },
        },
      ];
    }
  },
  created() {
    this.datePickerValue.startDate = DateTime.now().minus({days:30}).startOf('day').toFormat('dd/MM/yyyy');
    this.datePickerValue.endDate = DateTime.now().endOf('day').toFormat('dd/MM/yyyy');
  },
  async mounted() {
    while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    if(this.currentMerchant.status === 'building' && this.$route.query.fromAuth){
      this.$router.push({name: 'onboarding'});
      return;
    }

    await this.load();

    nextTick(() => {
      EventBus.on('resetTour', () => {
        if(this.$route.name === 'analytics'){
          resetTour(this.$route.name);
          siteTour(this.$route.name, this.stepsWithConditions);
        }
      })

      if(this.$route.name === 'analytics') siteTour(this.$route.name, this.stepsWithConditions);
    })
  }
}
</script>
