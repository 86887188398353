<template>
  <modal title="Relier ce choix à un produit" size="xs" close-id="link-modifier-to-item">
    <div class="py-3">

      <div class="bg-primary/5 px-5 py-4 rounded-md mb-6">
        <span class="text-gray-700">Permet de synchroniser un choix d'option avec un produit en cas d'indisponibilités.</span>
      </div>

      <select name="" id="" v-model="selected" class="form-select mb-4">
        <option :value="null">-- Choisir --</option>
        <option :value="item.id" v-for="item in menu.items.filter(f => f.archived === false)">{{item.name}}</option>
      </select>

      <div v-if="modifier && sameOptions.length>0" class="mb-10">
        <label for="link-same" class="cursor-pointer select-none block">
          <input type="checkbox" id="link-same" class="form-checkbox mr-1" v-model="massUpdate">
          Effectuer le changement pour les <b>{{sameOptions.length}}</b> autres options nommées <b>{{modifier.name}}</b> dans votre menu
        </label>

      </div>

      <div class="flex gap-4 justify-end mt-3">
        <button class="btn bg-primary text-white" @click="link">Relier à ce produit</button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {errorToast} from "@/services/SystemService";
import {useVfm} from "vue-final-modal";
import EventBus from "@/EventBus";
export default {
  components: {
    Modal
  },
  computed: {
    ...mapGetters({
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant'
    }),
    modifier(){
      return this.menu.modifiers.find(f => f.uid === this.modifierUid)
    },
    sameOptions(){
      if(!this.modifier) return []
      return this.menu.modifiers.filter(f => f.name.toLowerCase() === this.modifier.name.toLowerCase() && f.id !== this.modifier.id)
    }
  },
  props: {
    modifierUid: {
      type: String,
      required: true
    },
    actualLink: {
      type: String,
      required: false
    },
    forceSave: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return {
      massUpdate: false,
      selected: null
    }
  },
  methods:{
    async link(){
      if(this.forceSave){
        const modifierGroup = this.menu.modifierGroups.find(f => f.itemIds.includes(this.modifierUid));

        const update = [{
          id: this.modifier.id,
          name: this.modifier.name,
          price: this.modifier.price,
          description: this.modifier.description,
          available: !this.modifier.unavailable,
          productId: this.selected,
          sort: this.modifier.sort
        }];

        if(this.massUpdate){
          this.sameOptions.forEach(m => {
            update.push({
              id: m.id,
              name: m.name,
              price: m.price,
              description: m.description,
              available: !m.unavailable,
              productId: this.selected,
              sort: m.sort
            })
          })
        }
        await this.$store.dispatch('menu/updateMenuModifierBatchAction', {merchantId: this.currentMerchant.id, modifierGroupId: modifierGroup.id, update})
            .then(() => {
              if(this.massUpdate) {
                this.sameOptions.forEach(m => {
                  console.log('Update modifier', m.id, this.selected);
                  this.$store.commit('menu/MENU_MODIFIER_SET_PRODUCT_ID', {modifierId: m.id, productId: this.selected});
                })
              }
            })
      }
      EventBus.emit('modifier-group:link-modifier-to-product', {productId: this.selected, modifierUid: this.modifierUid});
      this.close();
    },
    close(){
      useVfm().close('link-modifier-to-item');
    }
  },
  async mounted() {
        while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    if(!this.menu || this.menu && parseInt(this.menu.merchantId) !== parseInt(this.currentMerchant.id)) await this.$store.dispatch('menu/getMerchantMenuAction', {merchantId: this.currentMerchant.id})

    if(this.actualLink && this.menu.items.filter(f => f.archived === false).some(p => p.id === this.actualLink)){
      this.selected = this.actualLink
    }
    if(this.menu.items.filter(f => f.archived === false).length === 0){
      errorToast("Votre menu n'a pas de produit pour l'instant...");
      this.close();
    }
  }
}
</script>
