<template>
  <merchant-layout :title="$t('nav.preorders')" :is-loading="!currentMerchant">

    <template v-slot:actions>
      <search-input v-if="preOrderList && preOrderList.items && preOrderList.items.length > 0" v-model="search"/>
    </template>

    <div class="bg-primary/5 px-5 py-4 rounded-md mb-5" v-if="preOrderList && preOrderList.items && preOrderList.items.length > 0">
      <span class="text-gray-700 ">Retrouver ci-dessous les précommandes passées par vos clients en avance. Pensez à les confirmer pour leur indiquer que les commandes seront effectivement préparées</span>
    </div>


    <page-block :title="g[0].preorderDay" no-title-padding v-for="(g,i) in groups" :key="i" class="mb-4">
      <template v-slot:no-padding>
        <table class="page-table">
          <tbody>
          <tr v-for="item in g" :key="item.id" :class="{'bg-red-50':item.status === 'canceled'}">
            <td class="w-20 md:w-40">{{item.ref}}</td>
            <td>
              <span v-if="item.status !== 'preorder'">{{formatIsoToHuman(item.takenAt)}}</span>
              <span v-else>{{ $t('orders.preorder_for', {hour: formatIsoToHuman(item.preorderFor)})}}</span>
            </td>
            <td>{{item.client.name}}</td>
            <td>{{formatPrice(item.amount)}}</td>
            <td>
              <span v-if="item.type==='delivery'">{{ item.courier ? $t('orders.deliver_by', {name: item.courier.name}) : $t('type.delivery') }}</span>
              <span v-if="item.type==='pickup'">{{$t('type.pickup')}}</span>
              <span v-if="item.type==='on_site_counter'">{{$t('type.on_site_counter')}}</span>
            </td>
            <td>
              <div class="flex justify-end items-center h-full">
                <OrderStatusBadge v-if="item.status !== 'preorder'" :status="item.status"/>
                <OrderPreorderBadge v-else :ring-at="item.preorderRingAt" :confirmed="item.preorderConfirmedAt!==null" :order="item"/>
              </div>
            </td>
            <td class="w-10 text-right">
              <button class="btn-sm xl:text-base text-white bg-primary rounded-md px-5 py-2 font-bold" @click="openOrder(item.id)">{{$t('cta.details')}}</button>
            </td>
          </tr>
          </tbody>
        </table>

      </template>
    </page-block>

    <page-block no-title-padding  v-if="!isLoading && preOrderList && preOrderList.items && preOrderList.items.length === 0">
      <table-no-elements title="Pas de précommande" description="Vous n'avez pas de précommande prévue dans les prochains jours sur votre restaurant"></table-no-elements>
    </page-block>



    <Pagination class="mt-4" v-if="!isLoading && preOrderList && preOrderList.items && preOrderList.items.length > 0" @changePage="loadPage" :is-loading="isPageLoading" :pagination="pagination"/>
  </merchant-layout>
</template>

<script>
import {mapGetters} from "vuex";
import {formatIsoToHuman, formatTimestampToHuman} from "@/services/TimeService";
import {formatPrice} from "@/services/FormatService";
import OrderStatusBadge from "@/components/Order/OrderStatusBadge.vue";
import OrderPreorderBadge from "@/components/Order/OrderPreorderBadge.vue";
import {useModal} from "vue-final-modal";
import Order from "@/views/Orders/Order.vue";
import Pagination from "@/components/Ui/Elements/Pagination.vue";
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";
import {DateTime} from "luxon";
import TableNoElements from "@/components/Ui/Elements/TableNoElements.vue";
import SearchInput from "@/components/Ui/Elements/SearchInput.vue";

export default {
  setup(){
    const orderModal = useModal({component: Order});
    return {formatTimestampToHuman, formatPrice, orderModal}
  },
  data(){
    return {
      pagination: null,
      page: 1,
      search: '',
      isLoading: true,
      isPageLoading: false,
    }
  },
  components: {SearchInput, TableNoElements, PageBlock, Pagination, OrderStatusBadge, OrderPreorderBadge},
  methods: {
    formatIsoToHuman,

    async openOrder(id){
      await this.orderModal.patchOptions({attrs: {orderId: id}});
      await this.orderModal.open();
    },

    async loadPage(page){
      this.page = page;
      this.isPageLoading = true;
      const request = await this.$store.dispatch('order/getPreOrderListAction', {merchantId: this.currentMerchant.id, page: this.page});
      this.pagination = request.pagination;
      this.isPageLoading = false;
    },

    async load(){
      if(!this.currentMerchant){
        return;
      }
      const preorders = this.$store.dispatch('order/getPreOrderListAction', {merchantId: this.currentMerchant.id, page: 1});
      this.pagination = preorders.pagination;
      this.isLoading = false;
    },


  },
  computed: {
    ...mapGetters(['currentMerchant']),
    ...mapGetters({
      preOrderList: 'order/preOrderList'
    }),
    groups(){
      if(!this.preOrderList || !this.preOrderList.items || this.preOrderList.items && this.preOrderList.items.length === 0){
        return [];
      }
      let preorderGroups = this.preOrderList.items.reduce(function (r, a) {
        const currentDay = DateTime.fromISO(a.preorderFor).setLocale('fr').toFormat('EEEE dd LLLL');
        a.preorderDay = currentDay;
        r[currentDay] = r[currentDay] || [];
        r[currentDay].push(a);
        return r;
      }, Object.create(null));

      preorderGroups = Object.values(preorderGroups).sort(function (a, b) {
        return a[0].preorderFor - b[0].preorderFor;
      });
      return preorderGroups;
    }
  },
  async mounted() {
        while (!this.currentMerchant) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    await this.load();
  }
}
</script>
